import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { Document as ContentfulDocument } from '@contentful/rich-text-types'
import { INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { isBrowser } from '../utils/isBrowser'

export function richTextToHtml(richText: unknown) {
    if (!richText) {
        return <p></p>
    }
    const hostname = isBrowser() ? location.hostname : 'labs.adobe.com'
    return documentToReactComponents(richText as ContentfulDocument, {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: function renderEmbeddedAsset(node) {
                return <img src={node.data.file.publicURL} />
            },
            [INLINES.HYPERLINK]: node => {
                return (
                    <a
                        href={node.data.uri}
                        target={`${
                            node.data.uri.includes(hostname)
                                ? '_self'
                                : '_blank'
                        }`}
                        rel={`${
                            node.data.uri.includes(hostname)
                                ? ''
                                : 'noopener noreferrer'
                        }`}
                    >
                        {node.content[0].value}
                    </a>
                )
            },
        },
    })
}
